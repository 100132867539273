* {
  outline: none;
  box-sizing: border-box;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none !important;
  }
}

html,
body,
#root {
  font-family: "Space Grotesk";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // overflow: hidden;
  // @media(max-wdith: 1000px) {
  //     overflow: auto;
  // }
}

body {
  margin: 0;
  box-sizing: border-box;
}

.app {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column;
  flex-direction: column;
  z-index: 1;
  background-size: cover;
  font-family: "Inter";
  // overflow: hidden;
  transition: all ease 0.33ms;
  // @media(max-wdith: 1000px) {
  //     overflow: auto;
  // }
}

.MuiDrawer-paperAnchorDockedLeft {
  border-right: 0 !important;
}

.ohm-card {
  max-width: 833px;

  .card-header {
    width: 100%;
    min-height: 33px;
    margin-bottom: 10px;

    h5 {
      font-weight: 600 !important;
    }
  }

  .help-text {
    margin: 10px 0px;
  }

  .data-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 12px 0px;
  }

  .data-row-centered {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    margin: 12px 0px;

    .marginedBtn {
      margin: 0.3rem;
    }
  }
}

.ohm-pairs {
  display: flex;
  align-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

input[type="number"] {
  -moz-appearance: textfield !important;
}

a:hover svg {
  color: inherit;
}

.MuiLink-root.active {
  color: #ffe300 !important;
  text-decoration: underline;
}

.ReactModal__Overlay {
  background-color: rgb(0 0 0 / 91%) !important;
  transition: opacity 0.4s ease 0s !important;
  position: relative;
  z-index: 100;
}

.MuiSkeleton-root {
  background-color: rgba(150, 150, 150, 0.3) !important;
}

.highcharts-label > text {
  fill: #1fc7d4 !important;
}

#WEB3_CONNECT_MODAL_ID {
  position: relative;
  z-index: 1000;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #05d4f6b2;
  }
}

// .web3modal-modal-card {
//     background-color: rgb(44, 48, 56) !important;
//     display: block !important;
//     max-width: 420px !important;
//     padding: 15px 0 !important;
// }

// .web3modal-provider-wrapper {
//     padding: 5px 1rem !important;
//     border: none !important;

// }

// .web3modal-provider-container {
//     flex-direction: row-reverse !important;
//     padding: 1rem !important;
//     background-color: rgb(76, 80, 86) !important;
//     justify-content: space-between !important;
//     border: 1px solid #4c5056 !important;
// }

// .web3modal-provider-container:hover {
//     border: 1px solid rgb(33, 114, 229) !important;
// }

// .web3modal-provider-description {
//     display: none !important;
// }

// .web3modal-provider-name {
//     margin-top: 0 !important;
//     color: white !important;
//     width: fit-content !important;
//     font-size: 1rem !important;
//     font-weight: 400 !important;
//     min-width: 165px;
//     text-align: left;
// }

// .web3modal-provider-icon {
//     width: 24px !important;
//     height: 24px !important;
// }

.MuiDialog-container > div {
  background: transparent !important;
  box-shadow: unset !important;
}

.MuiDialog-paper {
  margin: 0 !important;
}

.MuiDialog-paperWidthSm {
  max-width: none !important;
}

.connect-btn-style {
  position: relative;
  z-index: 100;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  padding: 20px 30px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  border-radius: 12px;
  background-color: transparent;
  //   box-shadow: inset 0 0 6px 0 rgb(241 172 93 / 30%);
  -webkit-transition: background-color 500ms ease, box-shadow 400ms ease,
    border 300ms ease;
  transition: background-color 500ms ease, box-shadow 400ms ease,
    border 300ms ease;
  font-family: "Chakra Petch", sans-serif;
  color: #f1ac5d;
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
}

body {
  background-color: #333;
}
.lp-box-content {
  background-color: transparent;
}
.lp-box {
  background: transparent !important;
  color: #fff;
}

// @media only screen and (max-width: 700px) {
//   .lp-box {
//     max-width: 90% !important;
//     width: 90%;
//   }
// }

.add-lp-btn-2 {
  margin-left: auto;
  text-decoration: none;
  text-shadow: 1px 1px 15px rgb(211 104 44 / 76%);
  color: #f6e7c9;
  fill: #f6e7c9;
}
.svg-btn {
  margin-bottom: -5px !important;
  fill: #f6e7c9 !important;
  margin-left: 3px;
}
.h1-txt {
  position: relative;
  z-index: 2;
  font-size: 60px;
  color: #f6e7c9;
  margin-bottom: 0px;
  text-shadow: 1px 1px 15px rgb(211 104 44 / 76%);
}

.lp-box-roi-txt-details {
  color: #f6e7c9;
}
.lp-box-roi-txt-details2 {
  color: #ffffff;
}
.h2-txt {
  position: relative;
  z-index: 2;
  color: #f6e7c9;
  margin-top: 0px;
  text-shadow: 1px 1px 15px rgb(211 104 44 / 76%);
}

.lp-box-content {
  position: relative;
  border: none;
  // border-style: solid;
  // border-width: 1px;
  // border-color: rgba(241, 172, 93, 0.3);
  background-color: transparent;
  // box-shadow: inset 0 0 6px 0 rgb(241 172 93 / 30%);
  transition: background-color 500ms ease, box-shadow 400ms ease,
    border 300ms ease;
  color: #f1ac5d;
}

.ela-svg-style {
  filter: brightness(46.5);
}

.section-background {
  // background: url("./assets/icons/section.svg");
  // background-repeat: no-repeat;
  // background-size: contain;
  background: url("./assets/icons/section.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  overflow-x: hidden;
}

.Enable-btn {
  border: none;
  border-radius: 12px !important;
  margin-left: 0px !important;
  font-weight: 700;
  width: 100%;
  cursor: pointer;
}

.harvest-btn-1 {
  align-self: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 12px !important;
  border-color: rgba(241, 172, 93, 0.3) !important;
  background-color: transparent;
  box-shadow: inset 0 0 6px 0 rgb(241 172 93 / 30%) !important;
  -webkit-transition: background-color 500ms ease, box-shadow 400ms ease,
    border 300ms ease;
  -webkit-transition: background-color 500ms ease, box-shadow 400ms ease,
    border 300ms ease;
  transition: background-color 500ms ease, box-shadow 400ms ease,
    border 300ms ease;
  font-family: "Chakra Petch", sans-serif;
  color: #f1ac5d !important;
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  padding: 13px 20px !important;
}

.btn-add-lp {
  align-self: center;
  border-style: solid;
  float: right;
  border-width: 1px;
  cursor: pointer;
  border-radius: 12px !important;
  border-color: rgba(241, 172, 93, 0.3) !important;
  background-color: transparent;
  box-shadow: inset 0 0 6px 0 rgb(241 172 93 / 30%) !important;
  transition: background-color 500ms ease, box-shadow 400ms ease,
    border 300ms ease;
  font-family: "Chakra Petch", sans-serif;
  color: #f1ac5d !important;
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  padding: 5px 10px 5px 10px;
}

.lp-div {
  padding-top: 20px;
  padding-bottom: 20px;
}

.modal-center-s {
  text-align: -webkit-center;
  padding-bottom: 30px;
}

.cancel-btn-1 {
  padding: 15px 30px !important;
  cursor: pointer;
}

.text--shadow {
  text-shadow: 1px 1px 15px rgb(211 104 44 / 76%);
}

.amountinput {
  text-shadow: 1px 1px 15px rgb(211 104 44 / 76%) !important;
}
.modal-txt-box {
  color: #f6e7c9 !important;
  text-shadow: 1px 1px 15px rgb(211 104 44 / 76%);
}

.cQhkZ {
  color: #f6e7c9 !important;
}

.none-txt {
  color: #fff !important;
  text-shadow: none !important;
}

.staked-s-2 {
  color: #f6e7c9 !important;
  margin-bottom: 5px;
  text-shadow: 1px 1px 15px rgb(211 104 44 / 76%);
}

.link-a {
  padding: 0px 20px;
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  font-family: "Chakra Petch", sans-serif;
  color: #ffe5d2 !important;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
}
.link-a:hover {
  color: rgba(241, 172, 93, 0.8) !important;
}
.footer-bg-color {
  background-color: transparent !important;
  border-top: 1px solid #f6e7c9;
}
.li-style {
  color: #ffffff !important;
}
.amnt-lock {
  text-shadow: 1px 1px 15px rgb(211 104 44 / 76%);
  color: #f6e7c9 !important;
  font-size: 16px !important;
  font-weight: 600;
  line-height: 1.5;
}

.buy-bunny-footer {
  height: 32px !important;
  position: relative;
  z-index: 100;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 30px !important;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(241, 172, 93, 0.3) !important;
  border-radius: 12px !important ;
  background-color: transparent;
  box-shadow: inset 0 0 6px 0 rgb(241 172 93 / 30%) !important;
  -webkit-transition: background-color 500ms ease, box-shadow 400ms ease,
    border 300ms ease !important;
  -webkit-transition: background-color 500ms ease, box-shadow 400ms ease,
    border 300ms ease !important;
  transition: background-color 500ms ease, box-shadow 400ms ease,
    border 300ms ease !important;
  font-family: "Chakra Petch", sans-serif;
  color: #f1ac5d !important;
  font-size: 15px !important;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}

.plus-icon {
  border: 2px solid #f6e7c9 !important;
  color: #f6e7c9 !important;
}
.minus-icon {
  border: 2px solid #f6e7c9 !important;
  color: #f6e7c9 !important;
}

.menu-topen-2 {
  height: 100% !important;
}
.opened-menu-h {
  border-top: 1px solid #f6e7c9;
  padding-top: 30px;
  padding-bottom: 30px;
}
.farms-top {
  margin-top: 30px;
  text-decoration: none;
}

.staking-bottom {
  margin-bottom: 20px;
  margin-top: 20px;
  text-decoration: none;
}

.buy-bunny-footer {
  height: 48px;
  position: relative;
  z-index: 100;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 30px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(241, 172, 93, 0.3);
  border-radius: 12px;
  background-color: transparent;
  box-shadow: inset 0 0 6px 0 rgb(241 172 93 / 30%) !important;
  -webkit-transition: background-color 500ms ease, box-shadow 400ms ease,
    border 300ms ease;
  -webkit-transition: background-color 500ms ease, box-shadow 400ms ease,
    border 300ms ease;
  transition: background-color 500ms ease, box-shadow 400ms ease,
    border 300ms ease;
  font-family: "Chakra Petch", sans-serif;
  color: #f1ac5d;
  font-size: 15px;
}

.max-btn {
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 7px;
  border: 1px solid;
  border-radius: 5px;
  padding-bottom: 2px;
  margin-bottom: 5px;
}
.coming-soon::after {
  content: "coming soon";
  font-size: 8px;
  position: absolute;
  left: 50%;
  bottom: -9px;
  right: auto;
}
.coming-soon {
  position: relative;
}
