#landing-view {
  padding-top: 140px;
  @media (max-width: 550px) {
    padding-top: 88px;
  }
  .title-box {
    position: relative;
    width: 1200px;
    height: 100%;
    border-radius: 16px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 30px;
    .logo-header {
      height: 150px;
      width: 200px;
    }
    @media (max-width: 1220px) {
      width: 98%;
    }
    @media (max-width: 880px) {
      width: 95%;
      flex-direction: column;
    }
    @media (max-width: 550px) {
      width: 90%;
      flex-direction: column;
      padding: 20px 18px;
      .logo-header {
        height: 150px;
        width: 200px;
      }
    }
    &-view {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 16px;
      h1 {
        max-width: 350px;
        font-size: 48px;
        line-height: 1.1;
        font-family: "Kanit, sans-serif";
        font-weight: 800;

        color: rgb(255, 255, 255);
        margin-bottom: 0px;

        @media screen and (min-width: 968px) {
          font-size: 56px;
        }
      }

      h2 {
        max-width: 350px;
        line-height: 1.1;
        color: rgb(255, 255, 255);
        font-size: 600px;
        font-size: 24px;
        white-space: nowrap;
        @media screen and (min-width: 968px) {
          font-size: 24px;
        }

        @media screen and (max-width: 550px) {
          font-size: 21px;
        }
      }
    }
    .header-card {
      position: absolute;
      width: calc(100% + 46px);
      top: -19px;
      left: -22px;
      z-index: 3;
      height: calc(100% + 41px);
      @media (max-width: 880px) {
        display: none;
        top: 2px;
      }
    }
    .tab-header-card {
      position: absolute;
      width: calc(100vw + 0px);
      top: -8px;
      left: -22px;
      z-index: 3;
      height: calc(100% + 41px);
      display: none;
      @media (max-width: 880px) {
        display: block;
        left: -18px;
      }
      @media (max-width: 550px) {
        display: none;
        top: 2px;
      }
    }
    .mob-header-card {
      position: absolute;
      width: calc(100vw + 26px);
      top: -19px;
      left: -42px;
      z-index: 3;
      height: calc(100% + 41px);
      display: none;
      // @media (max-width: 880px) {
      //   display: block;
      // }
      @media (max-width: 550px) {
        display: block;
        top: 2px;
      }
      @media (max-width: 450px) {
        left: -35px;
      }
      @media (max-width: 400px) {
        left: -32px;
      }
    }
  }

  .warning-box {
    background-image: linear-gradient(
      320deg,
      rgb(214, 132, 2),
      rgb(255, 188, 81)
    );
    padding: 2rem;
    margin: 1rem 0px 0px;
    text-align: center;
    position: relative;
    color: black;

    &-icon {
      width: 140px;
      position: absolute;
      top: calc(50% - 78px);
      left: calc(50% - 32rem);
      transform: rotate(-16deg);
      fill: rgba(255, 255, 255, 0.533);

      align-self: center;
      flex-shrink: 0;
    }

    &-title {
      font-weight: bold;
      color: black;
      text-align: center;
    }

    &-message1 {
      font-size: 1rem;
      margin: 1rem 0px;
      padding: 0px;
      border: 0px;
    }

    &-message2 {
      font-size: 0.9rem;
    }
  }

  .lp-container {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    min-height: calc(100vh - 64px);
    padding-top: 55px;
    padding-bottom: 32px;
    @media (max-width: 1220px) {
      max-width: 100%;
    }
    @media (max-width: 880px) {
      max-width: 95%;
    }
    @media (max-width: 550px) {
      padding-top: 25px;
    }

    .lp-boxes {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 9px;
      vertical-align: baseline;
      @media (max-width: 1220px) {
        gap: 0;
        column-gap: 0;
      }
      @media (max-width: 768px) {
        justify-content: space-between;
      }

      > * {
        min-width: 250px;
        max-width: 31.5%;
        width: 100%;
        margin: 0px 8px 32px;
        // @media (max-width: 1220px) {
        //   max-width: 48%;
        // }
        @media (max-width: 880px) {
          max-width: 50%;
          margin: 0px 0px 32px;
        }
        @media (max-width: 768px) {
          max-width: calc(50% - 20px);
        }
        @media (max-width: 700px) {
          max-width: calc(49% - 20px);
        }
        @media (max-width: 600px) {
          max-width: 49%;
        }
        @media (max-width: 550px) {
          min-width: 100%;
        }
      }
    }
  }
}
